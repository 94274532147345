import { Injectable } from '@angular/core';
import { HardcodedUser } from '../model/HardcodedUser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EMPTY, Observable, lastValueFrom, map, of, tap } from 'rxjs';
import { Role } from '../model/Role';
import { User } from '../model/api/user/User';
import { switchMap, catchError } from 'rxjs/operators';
import { UserRoles } from '../model/api/user/UserRoles';

@Injectable({
  providedIn: 'root'
})
export class HardcodedUserService {

  constructor(private httpClient: HttpClient) {
  }

  hardcodedUsers = [
    new HardcodedUser('Ansøger Anne', window.btoa('anne:cFlow@2300')),
    new HardcodedUser('Ansøger Anders', window.btoa('anders:cFlow@2300')),
    new HardcodedUser('Koordiantor Karin', window.btoa('karin:cFlow@2300')),
    new HardcodedUser('Koordinator Klaus', window.btoa('klaus:cFlow@2300')),
    new HardcodedUser('Bestyrelsesmedlem Bente', window.btoa('bente:cFlow@2300')),
    new HardcodedUser('Bestyrelsesmedlem Bent', window.btoa('bent:cFlow@2300'))
  ]

  getCurrentUser(): string | undefined {
    const user = localStorage.getItem('user');
    const token = localStorage.getItem('token');

    if (!user || !token) {
      // this.logout();
      return undefined;
    }

    return user;
  }

  getPersonName(): string | undefined {
    let personName = localStorage.getItem('personName');
    const token = localStorage.getItem('token');

    if (!personName && token) {
      const tokenPayload = window.atob(token.split('.')[1]);
      personName = JSON.parse(tokenPayload)['person_name'];
      localStorage.setItem('personName', personName ?? '');
    }

    if (!personName || !token || personName === '') {
      return undefined;
    }

    return personName;
  }


  getToken() {
    const user = localStorage.getItem('user');
    const token = localStorage.getItem('token');

    if (!user || !token) {
      // this.logout();
      return undefined;
    }

    return token;
  }

  logout(reloadPage: boolean = false) {
    const currentUser = localStorage.getItem('user');
    if ((currentUser && currentUser.includes('nemlogin')) || window.location.href.includes("user=PRIVATE&token=invalid")) {
      const samllogoutURL = '/access/api/latest/saml/logout';
      localStorage.clear();
      window.location.href = samllogoutURL;
    } else {
      localStorage.clear();
      window.location.href = '/';
    }
  }

  login(hardcodedUser: HardcodedUser): Observable<any> {
    const headers = new HttpHeaders({ 'Authorization': 'Basic ' + hardcodedUser.auth });
    const options = {
      headers: headers,
      responseType: 'text' as const
    };

    return this.httpClient
      .get('/access/api/latest/authenticate', options)
      .pipe(
        tap(token => {
          const tokenPayload = window.atob(token.split('.')[1]);
          const user = JSON.parse(tokenPayload)['sub'];
          const cvr = JSON.parse(tokenPayload)['cvr'];
          const companyName = JSON.parse(tokenPayload)['company_name'];
          const scopes = JSON.parse(tokenPayload)['scopes'];
          const personName = JSON.parse(tokenPayload)['person_name'];

          localStorage.setItem('user', user);
          localStorage.setItem('token', token);
          localStorage.setItem('cvr', cvr);
          localStorage.setItem('companyName', companyName);
          localStorage.setItem('scopes', scopes);
          localStorage.setItem('personName', personName ?? '');
        })
      );
  }

  getUsersByCVR(cvr: string): Observable<User[]> {
    const currentUser = this.getCurrentUser();
    const token = this.getToken();

    if (!currentUser || !token) {
      return EMPTY;
    }

    const headers = new HttpHeaders({'Authorization': 'Bearer ' + token});
    const options = {headers: headers}
    return this.httpClient.get<object[]>('/access/api/latest/users/withCVR/' + cvr, { headers }).pipe(
      map(userPayloadList => userPayloadList.map(userPayload => this.toUser(userPayload)))
    );
  }

  getCurrentUserWithInformation(): Observable<User> {

    try {
      const currentUser = this.getCurrentUser();
      const token = this.getToken();

      if (!currentUser || !token) {
        return EMPTY;
      }

      const username = currentUser.split("\\")[1];
      const headers = new HttpHeaders({'Authorization': 'Bearer ' + token});
      
      return this.httpClient.get<object>(`/access/api/latest/users/withUsername/${username}`, { headers }).pipe(
        map(userPayload => {
          const user = this.toUser(userPayload);
          return user;
        })
      );
    } catch (error) {
      console.error("Error getting current user with information", JSON.stringify(error, null, 3));
      return EMPTY;
    }
  }

  getUserByUsername(username: string): Observable<User> {
    const currentUser = this.getCurrentUser();
    const token = this.getToken();

    if (!currentUser || !token) {
      return EMPTY;
    }

    const headers = new HttpHeaders({'Authorization': 'Bearer ' + token});
    
    return this.httpClient.get<object>(`/access/api/latest/users/withUsername/${username}`, { headers }).pipe(
      map(userPayload => {
        const user = this.toUser(userPayload);
        return user;
      })
    );
  }

  getUserByUsernameAsync(username: string): Promise<User>{
    return lastValueFrom(this.getUserByUsername(username));
  }

  getRoleByUsername(username: string): Observable<Role> {
    const currentUser = this.getCurrentUser();
    const token = this.getToken();

    if (!currentUser || !token) {
      return EMPTY;
    }

    const headers = new HttpHeaders({'Authorization': 'Bearer ' + token});

    return this.getUserByUsername(username).pipe(
      switchMap(userPayload => {
        const user = this.toUser(userPayload);
        const userId = user.id;

        return this.httpClient.get<object>(`/access/api/latest/users/withRoles/${userId}`, { headers }).pipe(
          map(userRolesPayload => {
            const userRoles = userRolesPayload as UserRoles;

            switch (userRoles.roles[0].roleName){
              case "lag-koordinatorer":
                return Role.COORDINATOR;
              case "lag-bestyrelse":
                return Role.BOARD_MEMBER;
              case "ansogere":
                return Role.APPLICANT;
              default:
                return Role.NONE;
            }
          }),
          catchError(error => {
            return of(Role.NONE);
          })
        );
      }),
      catchError(error => {
        return of(Role.NONE);
      })
    );
  }

  getRoleByUsernameAsync(username: string): Promise<Role> {
    return lastValueFrom(this.getRoleByUsername(username));
  }
  
  autoLogin(token: string): void {
    const jwtData = token.split('.')[1];
    const decodedJwtJsonData = this.base64DecodeUnicode(jwtData);
    const decodedJwtData = JSON.parse(decodedJwtJsonData);

    localStorage.setItem('user', decodedJwtData['sub']);
    localStorage.setItem('token', token);
    localStorage.setItem('cvr', decodedJwtData['cvr']);
    localStorage.setItem('companyName', decodedJwtData['company_name']);
    localStorage.setItem('scopes', decodedJwtData['scopes']);
  }

  getCurrentUserRole(): Role {
    if (this.isCurrentUserRoleApplicant()) {
      return Role.APPLICANT;
    } else if (this.isCurrentUserRoleCoordinator()) {
      return Role.COORDINATOR;
    } else if (this.isCurrentUserRoleBoardMember()) {
      return Role.BOARD_MEMBER;
    }

    return Role.NONE;
  }

  getCurrentUserCvr(): string {
    const cvr = localStorage.getItem('cvr');
    return cvr ? cvr : '';
  }

  getCurrentUserCompanyName(): string {
    const companyName = localStorage.getItem('companyName');
    return companyName ? companyName : '';
  }

  toUser(userPayload: object): User {
    const user_as_dict = userPayload as { [key: string]: string }
    const user: User = new User();
    user.companyName = user_as_dict['companyName'];
    user.cvr = user_as_dict['cvr'];
    user.email = user_as_dict['email'];
    user.fqn = user_as_dict['fqn'];
    user.id = user_as_dict['id'];
    user.idp = user_as_dict['idp'];
    user.personName = user_as_dict['personName'];
    user.username = user_as_dict['username'];

    return user;
  }

  private isCurrentUserRoleApplicant(): boolean {
    return this.hasRole('ROLE_DATA_APPLICATION_SUBMIT');
  }

  private isCurrentUserRoleCoordinator(): boolean {
    return this.hasRole('ROLE_DATA_LAG_WRITE');
  }

  private isCurrentUserRoleBoardMember(): boolean {
    return this.hasRole('ROLE_DATA_LAG_READ') && !this.hasRole('ROLE_DATA_LAG_WRITE');
  }

  private hasRole(role: string): boolean {
    const rawScopes = localStorage.getItem('scopes');

    if (rawScopes) {
      const scopes = rawScopes.split(',');
      return scopes && !!scopes.length && (scopes.indexOf(role) > -1);
    }

    return false;
  }

  private base64DecodeUnicode(str: string): string {
    return decodeURIComponent(window.atob(str).split('').map(c => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }
}
