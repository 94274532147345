<div class="h-14 bg-white drop-shadow-md">
    <div class="h-full flex justify-end" *ngIf="!currentUser">
        <a href="/access/saml/samllogin?targetUrl=/"
            class="text-lime-700 hover:text-lime-900 text-sm hover:bg-gray-50 h-full px-6 flex items-center justify-center">
          NEMLOG-IN
        </a>
        <ng-container *ngIf="isLocalhostOrTest()">
            <a *ngFor="let hardcodedUser of hardcodedUsers" href="#" (click)="logIn(hardcodedUser)"
                class="text-lime-700 hover:text-lime-900 text-xs hover:bg-gray-50 h-full px-6 flex items-center justify-center">
                Log ind som <br/>
                {{ hardcodedUser.name }}
            </a>
        </ng-container>
    </div>
    <div *ngIf="currentUser" class="h-full flex justify-end">
        <span class="text-lime-700 text-sm px-6 flex items-center justify-center">
            Logget ind som {{ personName ?? currentUser}}
        </span>
        
        <a href="#" (click)="logout()"
            class="text-lime-700 hover:text-lime-900 text-sm hover:bg-gray-50 h-full px-6 flex items-center justify-center">
            Log ud
        </a>
    </div>
</div>
